export default {
    params: null,
    pageLoading: false,
    cards: [
        {
            id: 1,
            text: 'Beurteilungsgenerator Gefährdungen am Arbeitsplatz',
            subcards: []
        },
        {
            id: 2,
            text: 'Meine Berichte',
            subcards: []
        },
        {
            id: 3,
            text: 'Selbstauskunft der Dienststelle',
            subcards: [
                {
                    id: 1,
                    text: 'Beurteilung der Arbeitschutzorganisation',
                    link: 'https://das-bundeswehr.de'
                },
                {
                    id: 2,
                    text: 'CMR-Überwachungsprogramm Selbstauskunft',
                    link: 'CMR'
                },
                {
                    id: 3,
                    text: 'Beurteilung der Arbeitschutzorganisation',
                    link: ''
                },
                {
                    id: 4,
                    text: 'CMR-Überwachungsprogramm Selbstauskunft',
                    link: ''
                },
                {
                    id: 5,
                    text: 'Beurteilung der Arbeitschutzorganisation',
                    link: ''
                },
                {
                    id: 6,
                    text: 'CMR-Überwachungsprogramm Selbstauskunft',
                    link: ''
                }
            ]
        },
        {
            id: 4,
            text: 'Nützliche Informationen',
            subcards: []
        },
        {
            id: 5,
            text: '...',
            subcards: []
        },
        {
            id: 6,
            text: '...',
            subcards: []
        },
    ],
    personalization: null,
    statuses: {
        green: 'Es liegt kein Handlungsbedarf vor und die Dienstelle erfüllt die gesetzlichen Anforderungen',
        yellow: 'Die Gefahren werden im großen und ganzen begegnet. Verbesserungspotenial besteht bei folgenden Punkten: ',
        red: 'Die gesetzlichen Amforderungen werden niocht erfült. Mängel müssen bei folgenden Punkten sofort abgestellt werden: '
    }
}
