export default {
    GET_CARDS (state) {
        return state.cards
    },
    GET_PARAMS (state) {
        return state.params
    },
    GET_PAGE_LOADING (state) {
        return state.pageLoading
    },
    GET_STATUSES (state) {
        return state.statuses
    },
    GET_PERSONALIZATION (state) {
        return state.personalization
    }
}
