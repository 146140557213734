export default {
    setParams({ commit }, data) {
        commit('SET_PARAMS', data)
    },
    setPageLoading({ commit }, data) {
        commit('SET_PAGE_LOADING', data)
    },
    setPersonalization ({ commit }, data) {
        commit('SET_PERSONALIZATION', data)
    }
}
