import Vue from 'vue'
import AppBar from "@/components/AppBar";
import ButtonComponent from "@/components/ButtonComponent";
import FooterBar from "@/components/FooterBar";
import BackButtonComponent from "@/components/BackButtonComponent";
import {ValidationObserver, ValidationProvider} from "vee-validate";

Vue.component('app-header-bar-component', AppBar)
Vue.component('app-footer-bar-component', FooterBar)
Vue.component('app-button-component', ButtonComponent)
Vue.component('app-back-button-component', BackButtonComponent)
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)