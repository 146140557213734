<template>
<v-footer min-height="96"
           max-height="96"
           elevation="1"
           color="#F9F9FA">
  <v-container class="mx-auto fill-height">
    <v-row class="ma-0 pa-0 d-flex justify-center align-center">
      <v-col cols="2" class="d-flex align-center justify-center">
        <v-btn color="#18283B" text plain :ripple="false" class="pt-sans text-capitalize">
          Impressum
        </v-btn>
      </v-col>
      <v-col cols="2" class="d-flex align-center justify-center">
        <v-btn color="#18283B" text plain :ripple="false" class="pt-sans text-capitalize">
          Datenschutz
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</v-footer>
</template>

<script>
export default {
  name: "AppBar"
}
</script>

<style scoped>

</style>