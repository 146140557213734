<template>
<v-app-bar min-height="96"
           max-height="96"
           elevation="1"
           color="#F9F9FA">
  <v-container class="mx-auto fill-height d-flex align-center">
    <v-row class="ma-0 pa-0">
      <v-col cols="3" class="d-flex justify-start">
        <v-btn icon>
          <v-img max-width="32" src="@/assets/icons/menu.svg"></v-img>
        </v-btn>
      </v-col>
      <v-col cols="6" class="d-flex align-center justify-center">
        <v-img max-width="123" src="@/assets/logo.svg"></v-img>
      </v-col>
      <v-col cols="3" class="d-flex justify-end">
        <v-btn icon>
          <v-icon style="font-size: 32px">mdi-account-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</v-app-bar>
</template>

<script>
export default {
  name: "AppBar"
}
</script>

<style scoped>

</style>