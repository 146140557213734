<template>
  <v-app>
    <app-header-bar-component/>
    <v-main>
      <transition name="slide" mode="in-out">
        <router-view></router-view>
      </transition>
    </v-main>
    <app-footer-bar-component/>
    <v-overlay v-if="pageLoading" opacity="1" color="#F7F7F7"
               style="position: fixed; top: 0; left: 0"
               z-index="9999" class="d-flex align-center justify-center">
      <v-img src="@/assets/logo.svg" max-width="280"></v-img>
    </v-overlay>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
    }
  },
  computed: {
    pageLoading () {
      return this.$store.getters.GET_PAGE_LOADING
    }
  }
};
</script>
