import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/dein-dashboard/:page',
    name: 'Page',
    component: () => import('../views/Page.vue')
  },
  {
    path: '/cmr',
    name: 'CMR',
    component: () => import('../views/CMR.vue')
  },
  {
    path: '/cmr/questions',
    name: 'Questions',
    component: () => import('../views/Questions.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  store.dispatch('setPageLoading', true).catch()
  next()
})

router.afterEach(() => {
  setTimeout(() => {
    store.dispatch('setPageLoading', false).catch()
  }, 1500)
})

export default router
