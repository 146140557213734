<template>
<v-btn color="#D8BC76"
       outlined
       tile
       class="d-flex justify-center align-center"
       style="padding: 12px 40px">
  <span class="mr-2">{{ text }}</span>
  <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7089 0.597182C17.1536 0.856171 16.8929 1.55583 17.1455 2.10904C17.2112 2.25287 17.9047 3.00259 19.1694 4.29709C20.2275 5.38015 21.0932 6.28486 21.0932 6.30762C21.0932 6.33032 16.5288 6.34892 10.95 6.34892H0.806885L0.571987 6.47623C0.210741 6.67203 0.024656 6.98414 0.00232791 7.43176C-0.0197337 7.87402 0.114288 8.18969 0.435355 8.45201L0.640144 8.61931L10.8667 8.63342C16.4913 8.64114 21.0932 8.66592 21.0932 8.68841C21.0932 8.71089 20.2177 9.62775 19.1476 10.7258C17.0476 12.8809 17.0022 12.9396 17.056 13.4297C17.0918 13.7564 17.3206 14.1418 17.5853 14.3216C17.883 14.524 18.3751 14.5596 18.6729 14.4005C18.9378 14.259 24.778 8.25348 24.9068 7.99017C25.0379 7.72226 25.0296 7.25456 24.8887 6.97807C24.7258 6.65802 18.8866 0.687724 18.6303 0.579128C18.366 0.467086 17.9712 0.474855 17.7089 0.597182Z" fill="#D8BC76"/>
  </svg>
</v-btn>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    text: null
  }
}
</script>

<style scoped>

</style>