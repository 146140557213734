export default {
    SET_PARAMS (state, params) {
        state.params = params
    },
    SET_PAGE_LOADING (state, pageLoading) {
        state.pageLoading = pageLoading
    },
    SET_PERSONALIZATION (state, personalization) {
        state.personalization = personalization
    }
}
